import { pluck } from 'rxjs/operators';
import { fetchAsObservable } from 'fetcher!sofe';

export function getTimeEntry(timeEntryId) {
  return fetchAsObservable(`/api/invoices/time-entries/${timeEntryId}`).pipe(pluck('time_entries'));
}

export function addTimeEntry(timeEntry = {}) {
  return fetchAsObservable('/api/invoices/time-entries', {
    method: 'POST',
    body: { time_entries: timeEntry },
  }).pipe(pluck('time_entries'));
}

export function updateTimeEntry(timeEntry = {}) {
  return fetchAsObservable(`/api/invoices/time-entries/${timeEntry.id}`, {
    method: 'PATCH',
    body: { time_entries: timeEntry },
  });
}

export function archiveTimeEntries(timeEntries) {
  return fetchAsObservable('/api/invoices/time-entries/_bulk:archive', {
    method: 'PATCH',
    body: { time_entries: timeEntries },
  });
}

export function unArchiveTimeEntries(timeEntries) {
  return fetchAsObservable('/api/invoices/time-entries/_bulk:unarchive', {
    method: 'PATCH',
    body: { time_entries: timeEntries },
  });
}

export function deleteTimeEntries(timeEntries = []) {
  return fetchAsObservable(`/api/invoices/time-entries/${timeEntries.map(timeEntry => timeEntry.id).join(',')}`, {
    method: 'DELETE',
  });
}

export function undeleteTimeEntries(timeEntries = []) {
  return fetchAsObservable(
    `/api/invoices/time-entries/undelete/${timeEntries.map(timeEntry => timeEntry.id).join(',')}`,
    { method: 'PATCH' }
  );
}

export function getTimeEntriesForTask(taskId) {
  return fetchAsObservable(`/api/time-entries/time_logged_to_task/${taskId}`).pipe(pluck('tasks'));
}

export function getEmptyTimeEntryObject(client_id) {
  return {
    id: makeid(),
    client_id,
    started_at: null,
    ended_at: null,
    service_code: null,
    unsaved: true,
  };
}

function makeid() {
  let text = '';
  let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 5; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export function getDashboardFilterGroups(type) {
  return fetchAsObservable(`/api/filter_groups?type=${type}`);
}

export function editMultipleFilterGroups(filterGroups = {}) {
  return fetchAsObservable('/api/filter_groups', {
    method: 'PUT',
    body: filterGroups,
  });
}

export function addFilterGroup(filter_groups = {}) {
  return fetchAsObservable('/api/filter_groups', {
    method: 'POST',
    body: { filter_groups },
  });
}

export function updateFilterGroup(filter_groups = {}, id) {
  return fetchAsObservable(`/api/filter_groups/${id}`, {
    method: 'PATCH',
    body: { filter_groups },
  });
}

export function deleteFilterGroup(filter_id) {
  return fetchAsObservable(`/api/filter_groups/${filter_id}`, {
    method: 'DELETE',
  });
}

export function bulkUpdateTimeEntries(data = {}) {
  return fetchAsObservable('/api/time-entries/bulk', {
    method: 'PATCH',
    body: data,
  });
}

export function getTimeSettings() {
  return fetchAsObservable('/api/time_settings').pipe(pluck('required_fields'));
}

export function updateTimeSettings(required_fields = []) {
  return fetchAsObservable('/api/time_settings', {
    method: 'PUT',
    body: { required_fields },
  });
}
