import { useState, useEffect } from 'react';
import { isEmpty, cloneDeep, each } from 'lodash';
import { DateTime } from 'luxon';
import { featureEnabled } from 'feature-toggles!sofe';
import routingNumValidator from 'bank-routing-number-validator';
import { billingCalc } from 'src/invoices/invoices.helper';
import { handleError } from 'src/common/handle-error.helper';
import { dateRangeTypes, dateRanges } from 'src/common/components/column_header/filter/date-filter.helper';
import {
  getPayment,
  createPayment,
  schedulePayment,
  savePayment,
  saveScheduledPayment,
} from 'src/resources/payments.resources';
import { getCredit, createRecurringPayment } from 'src/resources/invoices.resources';
import { createBankAccount, createCard } from 'src/resources/payment-settings.resources';
import { isProd, luxonISODateFormat } from '../billing-helpers';

export const ccTypes = ['Visa', 'MasterCard', 'American Express', 'Discover'];
export const paysafeCardTypes = { VI: 'Visa', MC: 'MasterCard', AM: 'American Express', DI: 'Discover' };
export const cardIconNames = {
  visa: 'billing-visa',
  mc: 'billing-mastercard',
  amex: 'billing-amex',
  discover: 'billing-discover',
  // Remove below with Paysafe
  VI: 'billing-visa',
  MC: 'billing-mastercard',
  AM: 'billing-amex',
  DI: 'billing-discover',
};

export const acceptedCardTypes = ['Visa', 'MasterCard', 'Discover'];

export const paymentSteps = {
  SelectClient: 0,
  SelectInvoices: 1,
  SelectRecurrence: 2,
  PaymentDetails: 3,
  ConfirmDetails: 4,
  Processing: 5,
  Receipt: 6,
  Error: 7,
};

export const paymentViewMode = {
  Settings: 'settings',
  Payments: 'payments',
};

export const paymentErrors = {
  declinedCard: {
    title: 'Payment Declined',
    subject: 'This credit card was declined for the following reason(s):',
    details: '',
  },
  failedGeneric: {
    title: 'Payment Failed',
    subject: 'This payment could not be processed for the following reason(s):',
    details: '',
  },
  saveCard: {
    title: 'Payment Failed',
    subject: 'Unable to save credit card:',
    details: '',
  },
  saveBank: {
    title: 'Payment Failed',
    subject: 'Unable to save bank account:',
    details: '',
  },
};

export const paymentStepConfig = [
  {
    // Select Client
    title: (isEdit, _, isClient) => (isEdit ? 'Edit a Payment' : isClient ? 'Make a Payment' : 'Create a Payment'),
    validate: ({ clientId }) => clientId,
    nextButton: () => 'Continue',
  },
  {
    // Select Invoices
    title: (isEdit, _, isClient) => (isEdit ? 'Edit a Payment' : isClient ? 'Make a Payment' : 'Create a Payment'),
    validate: ({ total }) => total > 0,
    nextButton: () => 'Continue',
  },
  {
    // Select Recurrence
    title: (isEdit, _, isClient) => (isEdit ? 'Edit a Payment' : isClient ? 'Make a Payment' : 'Create a Payment'),
    validate: ({ selectedRecurrence }) => selectedRecurrence,
    nextButton: () => 'Continue',
  },
  {
    // Payment Details
    title: (isEdit, _, isClient) => (isEdit ? 'Edit a Payment' : isClient ? 'Make a Payment' : 'Create a Payment'),
    validate: ({
      method,
      paymentDate,
      ccInfo,
      achInfo,
      paysafe,
      adyenInstance,
      savedMethod,
      savedMethodName,
      paymentMethod,
    }) => {
      const validPaymentDate = paymentDate && DateTime.fromISO(paymentDate).isValid;
      //Adyen: Checks if chosen saved credit card method is expired
      if (
        !isEmpty(paymentMethod) &&
        paymentMethod?.card_type !== 'ach' &&
        !paymentMethod?.isExpired &&
        !adyenInstance
      ) {
        return validPaymentDate;
      }
      //Adyen: Checks if all Adyen fields are filled and enables/disables based on saved method option
      if (adyenInstance) {
        if (savedMethod && !savedMethodName.trim()) return false;
        return validPaymentDate;
      }
      //Adyen: Checks if chosen saved credit card method is expired and disables until card is updated
      if (paymentMethod?.card_type !== 'ach' && paymentMethod?.isExpired) {
        if (adyenInstance) return true;
        return false;
      }
      //Paysafe: Checks credit card expiration (Remove later)
      if (method?.includes('cpStoredCreditCard') && !ccInfo.isExpired) {
        return validPaymentDate && ccInfo.validCardType;
      }
      //Paysafe: Default to checking paysafe info (Remove later)
      return (
        isValidPaymentDetails(method, paymentDate, ccInfo, achInfo, paysafe) &&
        (method === 'cpCreditCard' ? ccInfo.validCardType && validPaymentDate : validPaymentDate)
      );
    },
    prevButton: () => 'Back',
    nextButton: () => 'Continue',
  },
  {
    // Confirm Details
    title: (isEdit, isFuturePayment) =>
      isEdit ? 'Confirm Changes' : isFuturePayment ? 'Verify and Schedule Payment' : 'Verify and Confirm Payment',
    validate: () => true,
    prevButton: () => 'Back',
    nextButton: isFuturePayment => (isFuturePayment ? 'Schedule' : 'Confirm and pay'),
  },
  {
    //Processing
    title: () => 'Processing...',
  },
  {
    // Receipt
    title: (isEdit, isFuturePayment) =>
      isEdit ? 'Payment Modified' : isFuturePayment ? 'Payment Scheduled' : 'Payment Submitted',
    validate: () => true,
    nextButton: () => 'Done',
  },
  {
    // Error
    title: () => '',
    validate: () => true,
    nextButton: () => 'Edit payment details',
  },
];

export const paymentMethods = [
  { key: 'cpStoredCreditCard', value: 'Credit Card', type: ['stored'] },
  { key: 'cpStoredBankAccount', value: 'ACH', type: ['stored'] },
  {
    key: 'cpCreditCard',
    value: featureEnabled('toggle_gs_display_debit_type') ? 'Credit/Debit Card' : 'Credit Card',
    type: ['canopy'],
  },
  { key: 'cpAch', value: 'ACH', type: ['canopy'] },
  { key: 'cash', value: 'Cash', type: ['manual'] },
  { key: 'check', value: 'Check', type: ['manual'] },
  { key: 'other', value: 'Other', type: ['manual'] },
];

export const paymentTypes = {
  oneTime: 'oneTime',
  recurring: 'recurring',
};

export const convertToCurrencyText = (number, isCurrency = true, allowNegative = false, digits = 2) => {
  if (!number || isNaN(number) || (!allowNegative && number <= 0)) number = 0;

  let usCurrency = new Intl.NumberFormat('en-US', {
    style: isCurrency ? 'currency' : 'decimal',
    currency: 'USD',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });

  return usCurrency.format(number);
};

export const separateName = name => {
  const names = name.split(' ');
  return { first_name: names[0], last_name: names[names.length - 1] };
};

const preparePayment = (payment, users) => {
  billingCalc.convertToFixedString(payment, 'amount');
  each(payment.invoice_payments, invoicePayment => {
    billingCalc.convertToFixedString(invoicePayment, 'amount');
  });

  billingCalc.convertArrayToFixedStrings(payment.refunds, 'amount');

  // Only attach the notification block if there are users to notify
  if (users) {
    payment.notifications = {
      users: users,
      url: `${window.location.origin}/m/clients/${payment.relationships.for.id}/billing/payment/${encodeURI(
        '{paymentId}'
      )}`,
    };
  }
};

export const fetchPaymentNumber = id => {
  return new Promise(resolve => {
    getPayment({ paymentId: id }).subscribe(
      response => {
        resolve({
          id,
          number: response.payment_number,
          active: response.settlement_status === 'complete',
        });
      },
      e => {
        handleError(e);
        resolve({ id, active: false });
      }
    );
  });
};

export const fetchPayment = id => {
  return new Promise(resolve => {
    getPayment({ paymentId: id }).subscribe(resolve, e => {
      handleError(e);
      resolve({ id, active: false });
    });
  });
};

export const fetchCredit = id => {
  return new Promise(resolve => {
    getCredit(id).subscribe(
      response => {
        resolve({ id, number: response.credit_number, active: true });
      },
      e => {
        handleError(e);
        resolve({ id, active: false });
      }
    );
  });
};

const prepareScheduledPayment = (payment, cardId) => {
  const paymentDetails = cloneDeep(payment);
  return {
    description: 'one-time',
    start_date: DateTime.fromISO(paymentDetails.date).toFormat(luxonISODateFormat),
    template: {
      amount: payment.amount,
      card_id: cardId,
      client_notes: payment.notes,
      credit_description: payment.credit_description,
      invoice_payments: payment.invoice_payments,
      relationships: paymentDetails.relationships,
    },
  };
};

const prepareRecurringPayment = (cardId, terms) => {
  return {
    payment_details: {
      card_id: cardId,
      terms: terms,
    },
  };
};

export const maybeSaveAccount = (achInfo, clientId) => {
  return new Promise((resolve, reject) => {
    const {
      saveAccount,
      accountName,
      accountNumber,
      accountType,
      routingNumber,
      firstName,
      lastName,
      street,
      street2,
      city,
      state,
      zip,
      isPreferred,
      nickname,
    } = achInfo;
    if (saveAccount) {
      const ach = {
        ach_details: {
          account_holder_name: accountName,
          account_number: accountNumber,
          account_type: accountType.toUpperCase(),
          routing_number: routingNumber,
          first_name: firstName,
          last_name: lastName,
          street,
          street2,
          city,
          state,
          zip,
        },
        is_preferred: isPreferred,
        team_can_use: achInfo.teamCanUse,
        nickname,
        relationships: { for: { client_id: clientId } },
      };
      createBankAccount({ ach }).subscribe(
        response => {
          resolve(response);
          window.dispatchEvent(new CustomEvent('billing-ui::payment-method-saved'));
        },
        error => {
          handleError(error);
          reject(error);
        }
      );
    } else {
      resolve();
    }
  });
};

export const maybeSaveCard = (ccInfo, clientId, token) => {
  return new Promise((resolve, reject) => {
    if (ccInfo.saveCard) {
      const card = {
        card_details: {
          ...separateName(ccInfo.name),
        },
        cc_token: token,
        is_preferred: ccInfo.isPreferred,
        team_can_use: ccInfo.teamCanUse,
        nickname: ccInfo.nickname,
        relationships: { for: { client_id: clientId } },
      };

      createCard({ card }).subscribe(
        response => {
          resolve(response);
          window.dispatchEvent(new CustomEvent('billing-ui::payment-method-saved'));
        },
        error => {
          handleError(error);
          reject(error);
        }
      );
    } else {
      resolve(token);
    }
  });
};

export const prepareAndCreatePayment = (
  payment,
  isFuturePayment,
  users,
  token,
  cardId,
  tokenType,
  name = '',
  isEdit,
  creditDescription,
  syncPaymentsToIntegration,
  hasIntegrationPayments
) => {
  payment = cloneDeep(payment);
  const { id: paymentId, isScheduledPayment } = payment;

  preparePayment(payment, users);

  if (tokenType && !isFuturePayment) {
    payment.date = Date.now();
  }

  payment.credit_description = creditDescription;

  if (isFuturePayment || isScheduledPayment) {
    payment = prepareScheduledPayment(payment, cardId);
  } else {
    if (tokenType === 'canopy_cc') {
      if (cardId) {
        payment.card_id = cardId;
      } else {
        payment.cc_token = token;
      }
      payment.method = tokenType;
      const names = separateName(name);
      payment.first_name = names.first_name;
      payment.last_name = names.last_name;
    } else if (tokenType === 'canopy_dd') {
      payment.method = tokenType;
    } else if (tokenType === 'stored_cc') {
      payment.card_id = cardId;
      payment.method = 'canopy_cc';
      payment.display_method = 'Credit Card';
    } else if (tokenType === 'stored_dd') {
      payment.card_id = cardId;
      payment.method = 'canopy_dd';
      payment.display_method = 'ACH';
    }
  }

  if (hasIntegrationPayments) {
    payment.third_party_sync_payment = syncPaymentsToIntegration;
    payment.event_origin = 'practitioner';
  }

  if (isEdit) {
    return new Promise((resolve, reject) => {
      if (isFuturePayment || isScheduledPayment) {
        saveScheduledPayment({ id: paymentId }, { recurrences: payment }).subscribe(
          response => {
            resolve(response);
          },
          error => {
            handleError(error);
            reject(error);
          }
        );
      } else {
        savePayment({ id: paymentId }, { payments: payment }).subscribe(
          response => {
            resolve(response);
          },
          error => {
            handleError(error);
            reject(error);
          }
        );
      }
    });
  } else if (isFuturePayment) {
    return new Promise((resolve, reject) => {
      schedulePayment({ recurrences: payment }).subscribe(
        response => {
          resolve(response);
        },
        error => {
          handleError(error);
          reject(error);
        }
      );
    });
  } else {
    return new Promise((resolve, reject) => {
      createPayment({
        payments: payment,
      }).subscribe(
        response => {
          resolve(response);
        },
        error => {
          handleError(error);
          reject(error);
        }
      );
    });
  }
};

export const prepareAndCreateRecurringPayment = (
  payment,
  cardId,
  selectedRecurrence,
  recurringPaymentDate,
  hasIntegrationPayments
) => {
  const recurrences = prepareRecurringPayment(
    cardId,
    recurringPaymentDate === 'invoice date' ? 0 : parseInt(selectedRecurrence.terms)
  );

  if (hasIntegrationPayments) {
    recurrences.third_party_sync_payment = true;
    recurrences.event_origin = 'practitioner';
  }

  return new Promise((resolve, reject) => {
    createRecurringPayment(selectedRecurrence.id, {
      recurrences,
    }).subscribe(
      response => {
        resolve(response);
      },
      error => {
        handleError(error);
        reject(error);
      }
    );
  });
};

export const isValidPaymentDetails = (method, paymentDate, ccInfo, achInfo, paysafe) => {
  if (!ccInfo && !achInfo) {
    return false;
  }

  if (method === 'cpCreditCard' || method.includes('cpStoredCreditCard')) {
    return (
      !!ccInfo.name &&
      ccInfo.zip?.length === 5 &&
      !!ccInfo.state &&
      !!ccInfo.city &&
      !!ccInfo.street &&
      (method === 'cpCreditCard' || (method.includes('cpStoredCreditCard') && ccInfo.isExpired)
        ? paysafe && paysafe.areAllFieldsValid()
        : true)
    );
  } else if (method === 'cpAch') {
    if (!achInfo) return false;
    return (
      achInfo.routingNumber.length === 9 &&
      routingNumValidator.ABARoutingNumberIsValid(achInfo.routingNumber) &&
      achInfo.accountNumber.length >= 4 &&
      achInfo.confirmAccountNumber === achInfo.accountNumber &&
      achInfo.firstName.length &&
      achInfo.lastName.length &&
      achInfo.street.length &&
      achInfo.city.length &&
      achInfo.state.length === 2 &&
      achInfo.zip.length === 5
    );
  } else {
    return paymentDate && DateTime.fromISO(paymentDate).isValid;
  }
};

export const useAdyen = isInternal => {
  const adyenClientKey =
    isProd() && !isInternal ? 'live_I7KMPLEZIFFEXHIEL4KJN2ZIFIWUTZDG' : 'test_GAGNONSUINGCJIUW5NL2CMJWJEYT6YFR';

  const adyenEnvironment =
    isProd() && !isInternal ? (featureEnabled('toggle_gs_adyen_form') ? 'live' : 'live-us') : 'test';

  return [adyenClientKey, adyenEnvironment];
};

export const usePaysafe = (onPaysafeInstance, identifier, onChange, mobile) => {
  const [cardNumberInvalid, setCardNumberInvalid] = useState(false);
  const [expiryMonthInvalid, setExpiryMonthInvalid] = useState(false);
  const [expiryDateInvalid, setExpiryDateInvalid] = useState(false);
  const [expiryYearInvalid, setExpiryYearInvalid] = useState(false);
  const [cvvInvalid, setCvvInvalid] = useState(false);
  const [cardType, setCardType] = useState('');

  const paysafe_api_key = isProd()
    ? 'U1VULTMyMDk3NDpCLXAxLTAtNWNmOTUzOTctMC0zMDJjMDIxNDdjMDQwNzA1ZGYzZjg3YmEzMjljYTY2OGEyNjExZjZiZjhiOTA0OWUwMjE0MzhkMTJjNGMxNGJlOGI2YzA4YmY2ZjNjOGRjOTBmODY1YWExMzJhZg=='
    : 'U1VULTI4MTAyMDpCLXFhMi0wLTVjMzhmYzQ3LTAtMzAyYzAyMTQwMmM4Njc1NWY2MmE2YjgxZTIwYzQ3YjEwZDBmM2IzZGZiYzFlYTg4MDIxNDRhNDdjM2Y5MGIxODUzZGY0ZWIxZDA3ZGI5ZWEwMzE5ZmE2M2RmYjY=';

  useEffect(() => {
    if (!window.paysafe) return;

    window.paysafe.fields.setup(
      paysafe_api_key,
      {
        environment: isProd() ? 'LIVE' : 'TEST',
        fields: {
          cardNumber: {
            selector: `#${identifier}-card-number`,
          },
          ...(mobile
            ? {
                expiryDate: {
                  selector: `#${identifier}-expiration-date`,
                  placeholder: 'mm/yy',
                },
              }
            : {
                expiryMonth: {
                  selector: `#${identifier}-expiration-date-month`,
                  placeholder: 'MM',
                },
                expiryYear: {
                  selector: `#${identifier}-expiration-date-year`,
                  placeholder: 'YY',
                },
              }),
          cvv: {
            selector: `#${identifier}-cvv`,
          },
        },
        style: {
          input: {
            'font-family': '"Source Sans Pro", "Helvetica Neue", Helvetica, sans-serif',
            'font-weight': 'normal',
            'font-size': '14px',
            color: '#555',
          },
        },
      },
      (instance, error) => {
        if (!error) {
          onPaysafeInstance(instance);

          instance
            .fields(`cardNumber ${mobile ? 'expiryDate' : 'expiryMonth expiryYear'} cvv`)
            .on('Valid Invalid Focus', handlePaysafeFieldChanged);
          instance
            .fields(`cardNumber ${mobile ? 'expiryDate' : 'expiryMonth expiryYear'} cvv`)
            .on('Blur', handlePaysafeFieldChanged);
          instance.fields('cardNumber').on('FieldValueChange', () => {
            setCardType(instance.getCardBrand());
          });
        }
      }
    );
  }, [window.paysafe]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'handlePaysafeFieldChanged', 'identifier', 'mobile', 'onPaysafeInstance', and 'paysafe_api_key'

  const handlePaysafeFieldChanged = (instance, event) => {
    switch (event.target.fieldName) {
      case 'CardNumber': {
        setCardNumberInvalid(
          event.type === 'Invalid' ? true : event.type === 'Blur' ? !instance.fields.cardNumber.isValid() : false
        );
        break;
      }
      case 'ExpiryDate': {
        setExpiryDateInvalid(
          event.type === 'Invalid' ? true : event.type === 'Blur' ? !instance.fields.expiryDate.isValid() : false
        );
        break;
      }
      case 'ExpiryMonth': {
        setExpiryMonthInvalid(
          event.type === 'Invalid' ? true : event.type === 'Blur' ? !instance.fields.expiryMonth.isValid() : false
        );
        break;
      }
      case 'ExpiryYear': {
        setExpiryYearInvalid(
          event.type === 'Invalid' ? true : event.type === 'Blur' ? !instance.fields.expiryYear.isValid() : false
        );
        break;
      }
      case 'Cvv': {
        setCvvInvalid(event.type === 'Invalid' ? true : event.type === 'Blur' ? !instance.fields.cvv.isValid() : false);
        break;
      }
    }
    onChange && onChange(instance.areAllFieldsValid());
  };
  return [cardNumberInvalid, expiryMonthInvalid, expiryDateInvalid, expiryYearInvalid, cvvInvalid, cardType];
};

export const tableFilterDateRanges = {
  [dateRangeTypes.today]: dateRanges[dateRangeTypes.today],
  [dateRangeTypes.yesterday]: dateRanges[dateRangeTypes.yesterday],
  [dateRangeTypes.currentWeek]: dateRanges[dateRangeTypes.currentWeek],
  [dateRangeTypes.lastWeek]: dateRanges[dateRangeTypes.lastWeek],
  [dateRangeTypes.currentMonth]: dateRanges[dateRangeTypes.currentMonth],
  [dateRangeTypes.lastMonth]: dateRanges[dateRangeTypes.lastMonth],
  [dateRangeTypes.yearToDate]: dateRanges[dateRangeTypes.yearToDate],
  [dateRangeTypes.custom]: dateRanges[dateRangeTypes.custom],
};

export const paymentDateRanges = {
  [dateRangeTypes.currentWeek]: dateRanges[dateRangeTypes.currentWeek],
  [dateRangeTypes.lastWeek]: dateRanges[dateRangeTypes.lastWeek],
  [dateRangeTypes.currentMonth]: dateRanges[dateRangeTypes.currentMonth],
  [dateRangeTypes.lastMonth]: dateRanges[dateRangeTypes.lastMonth],
  [dateRangeTypes.currentQuarter]: dateRanges[dateRangeTypes.currentQuarter],
  [dateRangeTypes.lastQuarter]: dateRanges[dateRangeTypes.lastQuarter],
  [dateRangeTypes.yearToDate]: dateRanges[dateRangeTypes.yearToDate],
  [dateRangeTypes.lastYear]: dateRanges[dateRangeTypes.lastYear],
  [dateRangeTypes.custom]: dateRanges[dateRangeTypes.custom],
};

export const upcomingDateRanges = {
  [dateRangeTypes.today]: dateRanges[dateRangeTypes.today],
  [dateRangeTypes.currentWeek]: dateRanges[dateRangeTypes.currentWeek],
  [dateRangeTypes.currentMonth]: dateRanges[dateRangeTypes.currentMonth],
  [dateRangeTypes.custom]: dateRanges[dateRangeTypes.custom],
};

export const depositDateRanges = {
  [dateRangeTypes.today]: dateRanges[dateRangeTypes.today],
  [dateRangeTypes.currentMonth]: dateRanges[dateRangeTypes.currentMonth],
  [dateRangeTypes.lastMonth]: dateRanges[dateRangeTypes.lastMonth],
  [dateRangeTypes.currentQuarter]: dateRanges[dateRangeTypes.currentQuarter],
  [dateRangeTypes.lastQuarter]: dateRanges[dateRangeTypes.lastQuarter],
  [dateRangeTypes.yearToDate]: dateRanges[dateRangeTypes.yearToDate],
  [dateRangeTypes.lastYear]: dateRanges[dateRangeTypes.lastYear],
  [dateRangeTypes.custom]: dateRanges[dateRangeTypes.custom],
};

export const dateFromCardExpiry = ({ month, year }) => {
  return DateTime.fromObject({ month, year });
};

export const isExpiredCard = paymentMethod => {
  return (
    paymentMethod?.card_expiry && dateFromCardExpiry(paymentMethod?.card_expiry) < DateTime.local().startOf('month')
  );
};
